import {Navbar} from "../components/Navbar";
import React from "react";
import {BannerPage} from "../components/BannerPage";

export const ImpactStudy = () => {
    return (

        <div>

           <BannerPage title={"Étude d'impacte & autres réglementations"} image={"/assets/images/gallery/fleurs.jpg"}/>
            <Navbar />
            {/*// <!-- hr -->*/}
            <hr className="line-vr-section"/>
            {/*// <!-- Project -->*/}
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <ul>
                                <li><p>• Evaluation environnementale volet Faune Flore (étude d’impact)</p></li>
                                <li><p>• Dossier cas par cas, autorisation</p></li>
                                <li><p>• Dérogation espèces protégées</p></li>
                                <li><p>• Dossier Loi sur l’eau</p></li>
                                <li><p>• Diagnostic Zones humides</p></li>
                            </ul>
                        </div>
                        <div className="col-md-8">
                            <div className="row align-items-stretch savoye-photos">
                                <div className="col-md-12 items mb-30">
                                    <a href="/assets/images/Projects/casque.jpg" className="d-block savoye-photo-item"
                                       data-caption="Project page" data-fancybox="gallery"> <img src="/assets/images/Projects/casque.jpg"
                                                                                                 alt=""
                                                                                                 className="img-fluid"/>
                                    </a>
                                </div>
                                {/*<div className="col-md-6 items mb-30">*/}
                                {/*    <a href="img/services/1.jpg" className="d-block savoye-photo-item" data-caption="Project page" data-fancybox="gallery"> <img src="img/services/1.jpg" alt="" className="img-fluid"/> </a>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-6 items mb-30">*/}
                                {/*    <a href="img/services/2.jpg" className="d-block savoye-photo-item" data-caption="Project page" data-fancybox="gallery"> <img src="img/services/2.jpg" alt="" className="img-fluid"/> </a>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
