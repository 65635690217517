import Radium from 'radium';
import React from "react";
import {Navbar} from "../components/Navbar";
import {GalleryComponent} from "../components/GalleryComponent";

export const Home = () => {
return (
        <div>
            {/* <!-- Preloader --> */}
            <div className="preloader-bg"></div>
            <div id="preloader">
                <div id="preloader-status">
                    <div className="preloader-position loader"> <span></span> </div>
                </div>
            </div>
            {/* <!-- Progress scroll totop --> */}
            <div className="progress-wrap cursor-pointer">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
            {/* <!-- Navbar --> */}
            <Navbar/>
            {/* <!-- Slider --> */}
            <header id="home" className="header slider-fade" data-scroll-index="0">
                <div className="owl-carousel owl-theme">
                    {/* <!-- The opacity on the image is made with "data-overlay-dark="number".You can change it using the numbers 0-9. --> */}
                    <div className="text-left item bg-img" data-overlay-dark="5" data-background="./assets/images/home/trees-3822149.jpg">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 mt-30">
                                        <div className="o-hidden">
                                            <h6><strong>Transmission</strong></h6>
                                            <h1>Écologique</h1>
                                            <p><strong>Société de conseil et d’ingénierie en écologie.</strong></p>
                                            {/*-<a href="project-page.html" className="btn float-btn flat-btn">Discover</a>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="text-left item bg-img" data-overlay-dark="3" data-background="./assets/images/home/mountains-6531903.jpg">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 mt-30">
                                        <div className="o-hidden">
                                            <h6>Residental</h6>
                                            <h1>Naturel</h1>
                                            <p>Architecture viverra tellus nec massa dictum the blackspace euismoe.<br/>Curabitur viverra the posuere hose aukue velition.</p>
                                            <a href="project-page.html" className="btn float-btn flat-btn">Discover</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-left item bg-img" data-overlay-dark="4" data-background="./assets/images/home/japan-8257601_1920.jpg">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 mt-30">
                                        <div className="o-hidden">
                                            <h6>Residental</h6>
                                            <h1>Environnemental</h1>
                                            <p>Architecture viverra tellus nec massa dictum the blackspace euismoe.<br/>Curabitur viverra the posuere hose aukue velition.</p>
                                            <a href="project-page.html" className="btn float-btn flat-btn">Discover</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </header>
            {/* <!-- Slider Services Box --> */}
            <div id="expertise" className="slider-services-wrapper" data-scroll-index="2">
                <div className="slider-box-content box-up clearfix">
                    <div className="row no-gutters">
                        <div className="col-md-3">
                            <div className="slider-services-box b-right b-bottom">
                                <a href="/engineering">
                                    <div className="slider-services-box-info">
                                        <img src="/assets/images/icons/oiseau.png" className="services-icon" alt=""/>
                                        <h5>Ingénierie écologique & Expertise naturaliste</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="slider-services-box b-bottom">
                                <a href="/impact-study">
                                    <div className="slider-services-box-info">
                                        <img src="/assets/images/icons/doc.png" className="services-icon" alt=""/>
                                        <h5>Etude d’impact & autres réglementations</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="slider-services-box b-left b-bottom">
                                <a href="/strategy">
                                    <div className="slider-services-box-info">
                                        <img src="/assets/images/icons/logo-table.png" className="services-icon" alt=""/>
                                        <h5>Stratégie d’entreprise & Conduite du changement</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="slider-services-box b-left b-right b-bottom">
                                <a href="/transmission">
                                    <div className="slider-services-box-info">
                                        <img src="/assets/images/icons/transmission.png" className="services-icon" alt=""/>
                                        <h5>Transmission & Animation</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- About --> */}
            <div id="about" className="about section-padding" data-scroll-index="1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="section-title">À Propos de nous</div>
                            <p>NATURAE est un bureau d’étude et de conseil scientifique en écologie.</p>
                            <p>Kelly Thomas a créé cette structure après 15 ans d'expérience en tant qu'écologue, et naturaliste afin d'accompagner des projets <br/> permettant de :</p>
                            <ul type={"circle"}>
                                <li>intégrer,</li>
                                <li>préserver, </li>
                                <li>restaurer, </li>
                                <li>transmettre : </li>

                            </ul>

                            <p className="text-color">la nature et donc la biodiversité</p>
                            <p>Les pôles d'expertise, tant dans le secteur public que privé, sont : </p>
                            <p><strong>Diagnostic écologique & Expertise naturaliste : </strong>Certifications & labels, expertise naturaliste et écologue, restauration des écosystème.</p>
                            <p><strong>Etude d’impact & autres réglementations</strong> (DLE Zones Humides, Etudes d’Incidences, ZAN, etc.)</p>
                            <p><strong>Stratégie d’entreprise & Conduite du changement : </strong>L'accompagnement  stratégique et opérationnel</p>
                            <p><strong>Transmission & Animation :</strong> L'animation d’ateliers et la concertation publique, Formation (ESSEC, MBA, etc.)</p>
                            <p>NATURAE s’associe, pour la complétude des études faune flore d’ordre réglementaire, à des naturalistes indépendants.</p>
                        </div>
                        <div className="col-md-5">
                            <div className="about-img">
                                <div className="img"> <img src="./assets/images/home/image-a-propos.png" className="img-fluid" alt=""/> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Projects --> */}
            {/*<div id="projects" className="savoye-project section-padding" data-scroll-index="2">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-md-12 text-center">*/}
            {/*                <div className="section-title">Nos références</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row savoye-project-items">*/}
            {/*            <div className="col-md-6 single-item interior">*/}
            {/*                <div className="savoye-project-wrap">*/}
            {/*                    <a href="project-page.html"><img src="./assets/images/home/eibsee-7352987_640.jpg" alt=""/></a>*/}
            {/*                    <div className="savoye-project-content">*/}
            {/*                        <p>Ingénierie écologique & Expertise naturaliste</p>*/}
            {/*                        <h3><a href="project-page.html">Bungalow Dark House</a></h3>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-6 single-item exterior">*/}
            {/*                <div className="savoye-project-wrap">*/}
            {/*                    <a href="project-page.html"><img src="./assets/images/home/lake-6641880.jpg" alt=""/></a>*/}
            {/*                    <div className="savoye-project-content">*/}
            {/*                        <p>Architecture</p>*/}
            {/*                        <h3><a href="project-page.html">Woodenist House<br/>Lumberjack</a></h3>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-6 single-item interior">*/}
            {/*                <div className="savoye-project-wrap">*/}
            {/*                    <a href="project-page.html"><img src="./savoye/img/projects/3.jpg" alt=""/></a>*/}
            {/*                    <div className="savoye-project-content">*/}
            {/*                        <p>Exterior Design</p>*/}
            {/*                        <h3><a href="project-page.html">Geometric Building</a></h3>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-6 single-item exterior">*/}
            {/*                <div className="savoye-project-wrap">*/}
            {/*                    <a href="project-page.html"><img src="./savoye/img/projects/4.jpg" alt=""/></a>*/}
            {/*                    <div className="savoye-project-content">*/}
            {/*                        <p>Architecture</p>*/}
            {/*                        <h3><a href="project-page.html">Arch Cloud Honna<br/>Didenton Villa</a></h3>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-6 single-item interior">*/}
            {/*                <div className="savoye-project-wrap">*/}
            {/*                    <a href="project-page.html"><img src="./savoye/img/projects/5.jpg" alt=""/></a>*/}
            {/*                    <div className="savoye-project-content">*/}
            {/*                        <p>Architecture</p>*/}
            {/*                        <h3><a href="project-page.html">Brown Monara House<br/>Ottova Canada</a></h3>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-6 single-item exterior">*/}
            {/*                <div className="savoye-project-wrap">*/}
            {/*                    <a href="project-page.html"><img src="./assets/images/home/lake-6641880.jpg" alt=""/></a>*/}
            {/*                    <div className="savoye-project-content">*/}
            {/*                        <p>Architecture</p>*/}
            {/*                        <h3><a href="project-page.html">Twin Forestland Home</a></h3>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/* <!-- Parallax Section --> */}
            <div id="parallax-section" className="parallax-header parallax-slider-fade" data-scroll-index="3">
                <div className="owl-carousel owl-theme">
                    {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
                    <div className="text-left item bg-img" data-overlay-dark="5" data-background="./assets/images/home/lake-6641880.jpg">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="o-hidden">
                                            <h6>Découvrir</h6>
                                            <h2>Ecologie</h2>
                                            <p><a href="@"><i className="ti-location-pin"></i> North Branch, NY, United States</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-left item bg-img" data-overlay-dark="5" data-background="./assets/images/home/japan-8257601_1920.jpg">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="o-hidden">
                                            <h6>Découvrir</h6>
                                            <h2>Nature</h2>
                                            <p><a href="#"><i className="ti-location-pin"></i> Princeton, NJ, United States</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-left item bg-img" data-overlay-dark="5" data-background="./assets/images/home/mountains-6531903.jpg">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="o-hidden">
                                            <h6>Découvrir</h6>
                                            <h2>Diversité</h2>
                                            <p><a href="#"><i className="ti-location-pin"></i> Ashburn, VA, United States</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Image GalleryComponent --> */}
            <GalleryComponent />

            {/* <!-- hr --> */}
            <hr className="line-vr-section"/>
            {/* <!-- Top Footer Banner --> */}
            <div id="contact" className="topbanner-footer" data-scroll-index="9">
                <div className=" banner-img valign bg-img bg-fixed" data-overlay-light="4" data-background="./savoye/img/slider/1.jpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-30 text-left caption">
                                <div className="section-title">Contactez-nous</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <h6>Téléphone</h6>
                                <h5 className="mb-30">(+33) 06.02.16.40.48</h5>
                            </div>
                            <div className="col-md-8">
                                <h6>Email</h6>
                                <h5 className="mb-30">kelly.thomas.naturea@gmail.com</h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer --> */}
            <footer id="footer" className="main-footer dark">

                <div className="sub-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="text-left">
                                    <p>© 2025, NATURAE Biodiversité. Tous droits réservés.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-right-left">
                                    <p><a href="#">Mentions légales</a> {/*<span>|</span> <a href="#"> Conditions générales de vente</a>*/}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


        </div>


    );
}

