import routes, {getRoutes} from "./routes";
import {Route, Routes, Navigate} from "react-router-dom";
import Radium, { StyleRoot } from 'radium';
import {QueryClient, QueryClientProvider} from "react-query";
function App() {
  const queryClient = new QueryClient();


  return (
      <QueryClientProvider client={queryClient}>
        <StyleRoot>
          <div>
            <Routes>
              {getRoutes(routes())}
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </div>
        </StyleRoot>
      </QueryClientProvider>
  );
}

export default Radium(App);
