import {Navbar} from "../components/Navbar";
import React from "react";
import {BannerPage} from "../components/BannerPage";

export const Strategy = () => {
    return (
        <div>
            <BannerPage title={"Stratégie d'entreprise & Conduite du changement"} image={"assets/images/gallery/fleurs.jpg"} />
            <Navbar />
            {/*// <!-- hr -->*/}
            <hr className="line-vr-section"/>
            {/*// <!-- Project -->*/}
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <ul>
                                <li><p>• Stratégie Zéro artificialisation nette</p></li>
                                <li><p>• Solutions fondée sur la Nature</p></li>
                                <li><p>• Stratégie Biodiversité interne et par activité</p></li>
                                <li><p>• Accompagnement conduite du changement stratégie interne Biodiversité</p></li>
                                <li><p>• Concertation publique</p></li>
                                <li><p>• Concertation des parties prenantes</p></li>
                            </ul>
                        </div>
                        <div className="col-md-8">
                            <div className="row align-items-stretch savoye-photos">
                                <div className="col-md-12 items mb-30">
                                    <a href="/assets/images/gallery/ref_Transmission-Animation_.jpg" className="d-block savoye-photo-item"
                                       data-caption="Project page" data-fancybox="gallery"> <img src="assets/images/gallery/ref_Transmission-Animation_.jpg"
                                                                                                 alt=""
                                                                                                 className="img-fluid"/>
                                    </a>
                                </div>
                                {/*<div className="col-md-6 items mb-30">*/}
                                {/*    <a href="img/services/1.jpg" className="d-block savoye-photo-item" data-caption="Project page" data-fancybox="gallery"> <img src="img/services/1.jpg" alt="" className="img-fluid"/> </a>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-6 items mb-30">*/}
                                {/*    <a href="img/services/2.jpg" className="d-block savoye-photo-item" data-caption="Project page" data-fancybox="gallery"> <img src="img/services/2.jpg" alt="" className="img-fluid"/> </a>*/}
                                {/*</div>*/}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>


    )
}
