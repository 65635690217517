import React from "react";

export const GalleryComponent = () => {
    return (
        <div id="gallery" className="section-padding" data-scroll-index="7">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-15">
                        <div className="section-title">Références</div>
                    </div>
                </div>
                <div className="row savoye-photos">
                    <div className="col-12 col-md-4 masonry-items">
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/gallery/Galerie1.jpg" data-caption="Alvar Alto Museum"> <img className="img-fluid" src="/assets/images/gallery/Galerie1.jpg" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Étude environnementale</h4>
                                            {/*<div className="gallery-masonry-item-category">Casablanca, Morocco</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/gallery/ref_strategie-entreprise.jpeg" data-caption="European Lard Station"> <img className="img-fluid" src="/assets/images/gallery/ref_strategie-entreprise.jpeg" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Stratégie d'entreprise</h4>
                                            {/*<div className="gallery-masonry-item-category">Strasbourg, France</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/gallery/ref_Transmission-Animation_.jpg" data-caption="Yabroudi Villa"> <img className="img-fluid" src="/assets/images/gallery/ref_Transmission-Animation_.jpg" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Animation Transmission</h4>
                                            {/*<div className="gallery-masonry-item-category">Dubai, United Arab Emirates</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/gallery/feuilles.jpg" data-caption="Yabroudi Villa"> <img className="img-fluid" src="/assets/images/gallery/feuilles.jpg" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Analyses de terrain</h4>
                                            {/*<div className="gallery-masonry-item-category">Dubai, United Arab Emirates</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 masonry-items">
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/gallery/Transmission-Animation_3.jpg" data-caption="Alvar Alto Museum"> <img className="img-fluid" src="/assets/images/gallery/Transmission-Animation_3.jpg" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Transmission de l'information</h4>
                                            {/*<div className="gallery-masonry-item-category">Muscat, Sultanate of Oman</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/gallery/Galerie2.jpeg" data-caption="Dalbourne Villa"> <img className="img-fluid" src="/assets/images/gallery/Galerie2.jpeg" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Analyses de terrain</h4>
                                            {/*<div className="gallery-masonry-item-category">Guangzhou, China</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/home/image-a-propos.png" data-caption="Amman Rotana Hotel"> <img className="img-fluid" src="/assets/images/home/image-a-propos.png" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Écologie</h4>
                                            {/*<div className="gallery-masonry-item-category">Amman, Jordan</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/logo/logo.png" data-caption="Amman Rotana Hotel"> <img className="img-fluid" src="/assets/logo/logo.png" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Biodiversité</h4>
                                            {/*<div className="gallery-masonry-item-category">Amman, Jordan</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 masonry-items">
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/gallery/ref-Etude-réglementaires.jpg" data-caption="Alvar Alto Museum"> <img className="img-fluid" src="/assets/images/gallery/ref-Etude-reglementaires.jpg" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Etude règlementaire</h4>
                                            {/*<div className="gallery-masonry-item-category">Casablanca, Morocco</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/*<div className="portfolio-item-wrapp">*/}
                        {/*    <div className="portfolio-item">*/}
                        {/*        <div className="gallery-masonry-wrapper">*/}
                        {/*            <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="./savoye/img/projects/6.jpg" data-caption="Dance and Music Theatre"> <img className="img-fluid" src="./savoye/img/projects/6.jpg" alt=""/>*/}
                        {/*                <div className="gallery-masonry-item-img"></div>*/}
                        {/*                <div className="gallery-masonry-item-content">*/}
                        {/*                    <h4 className="gallery-masonry-item-title">Dance The Theatre</h4>*/}
                        {/*                    <div className="gallery-masonry-item-category">Perth, Australia</div>*/}
                        {/*                </div>*/}
                        {/*            </a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/gallery/Galerie3.jpeg" data-caption="French Embassy"> <img className="img-fluid" src="/assets/images/gallery/Galerie3.jpeg" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Recherche de cavité</h4>
                                            {/*<div className="gallery-masonry-item-category">Casablanca, Morocco</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="portfolio-item-wrapp">
                            <div className="portfolio-item">
                                <div className="gallery-masonry-wrapper">
                                    <a className="gallery-masonry-item-img-link d-block savoye-photo-item" data-fancybox="images" href="/assets/images/gallery/Galerie3.jpeg" data-caption="French Embassy"> <img className="img-fluid" src="/assets/images/gallery/scarab.jpg" alt=""/>
                                        <div className="gallery-masonry-item-img"></div>
                                        <div className="gallery-masonry-item-content">
                                            <h4 className="gallery-masonry-item-title">Analyse de la faune</h4>
                                            {/*<div className="gallery-masonry-item-category">Casablanca, Morocco</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
