
import React from "react";
import {Route} from "react-router-dom";
import {Home} from "./pages/Home";
import {Engineering} from "./pages/Engineering";
import {ImpactStudy} from "./pages/ImpactStudy";
import {Strategy} from "./pages/Strategy";
import {Transmission} from "./pages/Transmission";
import {P404} from "./pages/P404";
export const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }

        if (route.route) {
            return <Route exact path={route.route} element={route.component} key={route.key} />;
        }

        return null;
    });

const routes = () => {
    let r = [

        {
            key: "index",
            route: "/",
            component: <Home />,
        },

        {
            key: "engineering",
            route: "/engineering",
            component: <Engineering />,
        },

        {
            key: "impact-study",
            route: "/impact-study",
            component: <ImpactStudy />,
        },

        {
            key: "strategy",
            route: "/strategy",
            component: <Strategy />,
        },

        {
            key: "transmission",
            route: "/transmission",
            component: <Transmission />,
        },

        {
            key: "404",
            route: "*",
            component: <P404 />,
        },

    ];

    return r ;
}

export default routes;
