
export const BannerPage = ({ title, subtitle, image }) => {
    return (
        <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark="4"
             data-background={image}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center caption mt-90">
                        <h1>{title}</h1>
                        {/*<h5><a href="/"><strong>Accueil</strong></a> / {subtitle}</h5>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}
