import {Navbar} from "../components/Navbar";
import React from "react";
import {BannerPage} from "../components/BannerPage";

export const Engineering = () => {
    return (
        <div>
            <BannerPage title={"Ingénierie écologique & Expertise naturaliste"} image={"assets/images/gallery/fleurs.jpg"} />
            <Navbar />
            {/*// <!-- hr -->*/}
            <hr class="line-vr-section"/>
            {/*// <!-- Project -->*/}
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        {/*// <!-- image -->*/}
                        <div className="col-md-4">
                            <ul>
                                <li><p>• Etudes faune et flore</p></li>
                                <li><p>• Diagnostic écologique pour toute typologie (construction, rénovation, aménagement, etc.)</p></li>
                                <li><p>• Certifications (Biodivercity, Effinature, HQE, Breeam, Leed, Petite Cité de caractère, Ecojardin, etc.)</p></li>
                                <li><p>• Diagnostic écologique pour tout secteur (Batiment, Cosmétique, Agroalimentaire, etc.)</p></li>
                                <li><p>• Analyse des impacts Biodiversité in situ et ex-situ tout secteur tout produit</p></li>
                                <li><p>• Accompagnement pour la Restauration des écosystèmes & Solution fondée sur la Nature</p></li>
                            </ul>
                        </div>
                        <div className="col-md-8">
                            <div className="row align-items-stretch savoye-photos">
                                <div className="col-md-12 items mb-30">
                                    <a href="/assets/images/Projects/Note_Nature_V1.pdf" className="d-block savoye-photo-item" data-caption="Project page" data-fancybox="gallery"> <img src="/assets/images/Projects/engenniering.png" alt="" className="img-fluid"/> </a>
                                </div>
                                {/*<div className="col-md-6 items mb-30">*/}
                                {/*    <a href="img/services/1.jpg" className="d-block savoye-photo-item" data-caption="Project page" data-fancybox="gallery"> <img src="img/services/1.jpg" alt="" className="img-fluid"/> </a>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-6 items mb-30">*/}
                                {/*    <a href="img/services/2.jpg" className="d-block savoye-photo-item" data-caption="Project page" data-fancybox="gallery"> <img src="img/services/2.jpg" alt="" className="img-fluid"/> </a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        {/*// <!-- text -->*/}

                    </div>
                </div>
            </div>
        </div>

    )
}
