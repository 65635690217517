import React from "react";
import {useLocation} from "react-router-dom";

export const Navbar = () => {
    const location = useLocation() ;
    const isHomePage = location.pathname === "/" ;
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                {/* <!-- Logo --> */}
                <div className="logo-wrapper">
                    <a className="logo" href="/"> <img src="./assets/logo/logo.png" alt=""/> </a>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="icon-bar"><i className="ti-menu"></i></span> </button>
                {/* <!-- Navbar links --> */}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto" >
                        <li className="nav-item"><a className="nav-link active" href="/#home" {...(isHomePage ? {"data-scrool-nav": "0"}: {})}>Accueil</a></li>
                        <li className="nav-item"><a className="nav-link" href="/#about"  {...(isHomePage ? {"data-scrool-nav": "1"}: {})}>À Propos</a></li>
                        <li className="nav-item"><a className="nav-link" href="/#expertise"  {...(isHomePage ? {"data-scrool-nav": "2"}: {})}>Pôles d'expertise</a></li>
                        <li className="nav-item"><a className="nav-link" href="/#gallery"  {...(isHomePage ? {"data-scrool-nav": "7"}: {})}>Galerie</a></li>
                        <li className="nav-item"><a className="nav-link" href="/#footer"  {...(isHomePage ? {"data-scrool-nav": "9"}: {})}>Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
