import {Navbar} from "../components/Navbar";
import React from "react";
import {BannerPage} from "../components/BannerPage";

export const Transmission = () => {
    return (
        <div>
            <BannerPage title={"Transmission & Animation"} image={"assets/images/gallery/fleurs.jpg"} />
            <Navbar />
            {/*// <!-- hr -->*/}
            <hr className="line-vr-section"/>
            {/*// <!-- Project -->*/}
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <ul>
                                <li><p>• Team building sur mesure (Biodiversité, Achat responsable, Economie circulaire, etc.)</p></li>
                                <li><p>• Formations adultes</p></li>
                                <li><p>• Enseignement (Licence, Master, etc.)</p></li>
                                <li><p>• Animations Nature</p></li>
                                <li><p>• Ateliers pédagogique sur-mesure</p></li>
                            </ul>
                        </div>
                        <div className="col-md-8">
                            <div className="row align-items-stretch savoye-photos">
                                <div className="col-md-12 items mb-30">
                                    <a href="/assets/images/gallery/ref_strategie-entreprise.jpeg" className="d-block savoye-photo-item"
                                       data-caption="Project page" data-fancybox="gallery"> <img src="/assets/images/gallery/ref_strategie-entreprise.jpeg"
                                                                                                 alt=""
                                                                                                 className="img-fluid"/>
                                    </a>
                                </div>
                                {/*<div className="col-md-6 items mb-30">*/}
                                {/*    <a href="img/services/1.jpg" className="d-block savoye-photo-item" data-caption="Project page" data-fancybox="gallery"> <img src="img/services/1.jpg" alt="" className="img-fluid"/> </a>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-6 items mb-30">*/}
                                {/*    <a href="img/services/2.jpg" className="d-block savoye-photo-item" data-caption="Project page" data-fancybox="gallery"> <img src="img/services/2.jpg" alt="" className="img-fluid"/> </a>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
